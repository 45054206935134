body {
  margin: 0;
  background-color: white;
  font-family: 'Jost' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.vertical-timeline::before {
  background: #f3f3f3 !important;
}
.modal-footer {
  display: block;
}
