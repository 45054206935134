.timelineMainDiv {
  display: flex;
}

.filterDiv {
  width: 20%;
  position: fixed;
}

.timelineModalDiv {
  width: 80%;
  margin-left: 20%;
}

.vertical-timeline-element-content {
  cursor: pointer;
}

@media only screen and (max-width: 1170px) {
  .filterDiv {
    display: none;
  }
  .timelineModalDiv {
    margin-left: auto;
    width: 100%;
  }
  .optionsButton {
    display: flex;
    position: fixed;
    right: 0;
    bottom: 2%;
    margin-right: 3%;
  }
}

.slide-pane__content {
  padding: 24px 0px;
}

@media only screen and (min-width: 1170px) {
  .optionsButton {
    margin-top: 32px;
    display: none;
  }
  .filterDiv {
    overflow: hidden;
    overflow-y: auto;
    height: 90%;
  }
  .timelineModalDiv {
    position: fixed;
    overflow-y: scroll;
    height: 90%;
  }
}

/* .btn-circle.btn-md {
    width: 50px;
    height: 50px;
    padding: 7px 10px;
    border-radius: 10px;
    font-size: 10px;
    text-align: center;
    background: ghostwhite;
    box-shadow: 2px 2px #212529;
}  */

.btn-circle.btn-md {
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 10px;
  text-align: center;
  box-shadow: 2px 2px #212529;
  background: ghostwhite;
  border: 2px solid #212529;
}
.vertical-timeline h2 {
  margin: 2rem 0;
  text-align: center;
  position: relative;
  color: #505050;
  padding: 1rem 1.5rem;
  background: #fff;
  width: fit-content;
  -webkit-box-shadow: 0 0 0 4px #fff, 0 3px 0 4px rgba(0,0,0,.05);
  box-shadow: 0 0 0 4px #fff, 0 3px 0 4px rgba(0,0,0,.05);
}

@media only screen and (min-width: 1170px){
  .vertical-timeline h2 {
    margin: 2rem auto;
  }
.vertical-timeline--two-columns .yearDiv:nth-child(2n) .vertical-timeline-element-content {
    float: right;
}
.vertical-timeline--two-columns .yearDiv:nth-child(2n) .vertical-timeline-element-content .vertical-timeline-element-content-arrow {
    top: 24px;
    left: auto;
    right: 100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
}
}
