@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
body {
  margin: 0;
  background-color: white;
  font-family: 'Jost' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.vertical-timeline::before {
  background: #f3f3f3 !important;
}
.modal-footer {
  display: block;
}

.Home-Title {
  font-size: 4vh;
  text-align: left;
}

.Homeblock {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
}

.Description {
  text-align: justify;
  line-height: 1.5em;
}

.CardImage {
  margin: auto;
  display: block;
  height: 100%;
  width: 100%
}

.LandingImage {
  background: url(/static/media/cards.4c98984c.jpg);
  height: calc(100vh - 80px);
  width: 100%;
  background-size: cover;
  padding-top: 2em;
}

.white {
  background: white;
}

.ButtonContainer {
  text-align: left;
  width: 100%;
}

.Timeline-text {
  font-size: 3vh;
}


.About {
   text-align: left;
   line-height: 1.8;
   padding-top: 1rem;
}
.Button {
  margin-bottom: 2rem;
}
.titlecase {
  text-transform: uppercase;
  letter-spacing: 2px;
}
.logo {
  max-width:200px;
}
#header ~ div {
  margin-top: 80px; /*offsets component div so sticky header doesn't overlay the content */
}
#header {
  background: #fff;
  display: block;
  width: 100%;
  z-index: 99;
  position: fixed; /*switch to absolute for non-sticky*/
  padding: 1rem;
  top:0;
}
#logo {
  float:left;
  align-items: center;
  display: flex;
}
#nav {
  float:right;
}
.brand{
  text-transform: uppercase;
  padding-left: 8px;
  font-size: 1.3rem;
}
#menu-button{
  width: 50px;
  position: relative;
  cursor: pointer;
  transition: all linear .3s;
  padding: 4px;
  z-index: 1;
}
#menu-button.active{
}
#line-1,#line-2,#line-3{
  width: 70%;
  background: #4453f7;
  height: 4px;
  margin: 6px auto;
  border-radius: 2px;
  transition: all linear .3s;
}

#line-1.active{
transform: translate(0px,8px) rotate(45deg);
}

#line-2.active{
opacity: 0;
}

#line-3.active{
transform: translate(0px, -12px) rotate(-45deg);
}

#menu{
  padding: 0 2rem 0;
  background: #fff;
  color: #333;
  font-family: arial;
  font-size: 1.3rem;
  display: block;
  position: absolute;
  top: 3.5rem;
  right: 0px;
  visibility: hidden;
}

#menu.active {
  animation-name: slideDown;
  animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-name: slideDown;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}

@keyframes slideDown {
  0% {
		transform: translateY(-100%);
	}
	50%{
		transform: translateY(8%);
	}
	65%{
		transform: translateY(-4%);
	}
	100% {
		transform: translateY(0%);
	}
}

@-webkit-keyframes slideDown {
  0% {
		transform: translateY(-100%);
	}
	50%{
		transform: translateY(8%);
	}
	65%{
		transform: translateY(-4%);
	}
	100% {
		transform: translateY(0%);
	}
}

#menu ul{
  list-style: none;
  padding: 1rem;
}
#menu a:link, #menu a:visited{
  text-decoration: none;
  color: #333;
}
#menu a:hover, #menu a:active{
  text-decoration: none;
  color: #000;
}

#menu ul li{
  margin: 18px 0;
}
.border-corners {
    position: relative;
}
.border-corners:before {
    top: 0;
    left: 0;
    border-width: 2px 0 0 2px;
}
.border-corners:after {
    bottom: 0;
    right: 0;
    border-width: 0 2px 2px 0;
}
.border-corners:after, .border-corners:before {
    position: absolute;
    width: 24px;
    height: 24px;
    content: " ";
    border-style: solid;
    border-color: #4453f7;
}
#content {
  height: 800px;
  background: #f1f1f1;
  display: block;
  margin: 2rem;
}

html{box-sizing:border-box}*,*:before,*:after{box-sizing:inherit}body{padding:0;line-height:1;font-size:16px;font-family:'Roboto', sans-serif;color:#202020;background-color:#fbfbfb;font-smooth:always;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.container{max-width:1140px;width:100%;margin:auto}.dd-wrapper{display:flex;min-height:38px;flex-wrap:wrap}.dd-wrapper .dd-header{background-color:white;border-color:#ccc;border-radius:4px;border-style:solid;border-width:1px;box-shadow:0 0.125rem 0.25rem rgba(0,0,0,0.075) !important;display:flex;justify-content:space-between;cursor:pointer;width:100%;padding:0 20px}.dd-wrapper .dd-header__title--bold{font-weight:bold}.dd-wrapper .dd-list{box-shadow:0 0.125rem 0.25rem rgba(0,0,0,0.075) !important;padding:0;margin:0;width:100%;margin-top:20px}.dd-wrapper .dd-list li{list-style-type:none}.dd-wrapper .dd-list li:first-of-type>button{border-top:1px solid #ccc;border-top-left-radius:4px;border-top-right-radius:4px}.dd-wrapper .dd-list li:last-of-type>button{border-bottom-left-radius:4px;border-bottom-right-radius:4px}.dd-wrapper .dd-list li button{display:flex;justify-content:space-between;background-color:white;font-size:16px;padding:15px 20px 15px 20px;border:0;border-bottom:1px solid #ccc;width:100%;text-align:left;border-left:1px solid #ccc;border-right:1px solid #ccc}.dd-wrapper .dd-list li button:hover,.dd-wrapper .dd-list li button:focus{cursor:pointer;font-weight:bold;background-color:#ccc}

.timelineMainDiv {
  display: flex;
}

.filterDiv {
  width: 20%;
  position: fixed;
}

.timelineModalDiv {
  width: 80%;
  margin-left: 20%;
}

.vertical-timeline-element-content {
  cursor: pointer;
}

@media only screen and (max-width: 1170px) {
  .filterDiv {
    display: none;
  }
  .timelineModalDiv {
    margin-left: auto;
    width: 100%;
  }
  .optionsButton {
    display: flex;
    position: fixed;
    right: 0;
    bottom: 2%;
    margin-right: 3%;
  }
}

.slide-pane__content {
  padding: 24px 0px;
}

@media only screen and (min-width: 1170px) {
  .optionsButton {
    margin-top: 32px;
    display: none;
  }
  .filterDiv {
    overflow: hidden;
    overflow-y: auto;
    height: 90%;
  }
  .timelineModalDiv {
    position: fixed;
    overflow-y: scroll;
    height: 90%;
  }
}

/* .btn-circle.btn-md {
    width: 50px;
    height: 50px;
    padding: 7px 10px;
    border-radius: 10px;
    font-size: 10px;
    text-align: center;
    background: ghostwhite;
    box-shadow: 2px 2px #212529;
}  */

.btn-circle.btn-md {
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 10px;
  text-align: center;
  box-shadow: 2px 2px #212529;
  background: ghostwhite;
  border: 2px solid #212529;
}
.vertical-timeline h2 {
  margin: 2rem 0;
  text-align: center;
  position: relative;
  color: #505050;
  padding: 1rem 1.5rem;
  background: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0 0 0 4px #fff, 0 3px 0 4px rgba(0,0,0,.05);
}

@media only screen and (min-width: 1170px){
  .vertical-timeline h2 {
    margin: 2rem auto;
  }
.vertical-timeline--two-columns .yearDiv:nth-child(2n) .vertical-timeline-element-content {
    float: right;
}
.vertical-timeline--two-columns .yearDiv:nth-child(2n) .vertical-timeline-element-content .vertical-timeline-element-content-arrow {
    top: 24px;
    left: auto;
    right: 100%;
    transform: rotate(0);
}
}

