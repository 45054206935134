/* ==========================================================================
   Dropdown menu
   ========================================================================== */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900');

/* RESETS
   ============================================ */

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  padding: 0;
  line-height: 1;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #202020;
  background-color: #fbfbfb;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1140px;
  width: 100%;
  margin: auto;
}

@mixin styling() {
  background-color: white;
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

/* DROPDOWN
   ============================================ */

.dd-wrapper {
  display: flex;
  min-height: 38px;
  flex-wrap: wrap;

  .dd-header {
    @include styling();
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    padding: 0 20px;

    &__title--bold {
      font-weight: bold;
    }
  }

  .dd-list {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 20px;

    li {
      list-style-type: none;

      &:first-of-type {
        > button {
          border-top: 1px solid #ccc;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      &:last-of-type > button {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      button {
        display: flex;
        justify-content: space-between;
        background-color: white;
        font-size: 16px;
        padding: 15px 20px 15px 20px;
        border: 0;
        border-bottom: 1px solid #ccc;
        width: 100%;
        text-align: left;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;

        &:hover,
        &:focus {
          cursor: pointer;
          font-weight: bold;
          background-color: #ccc;
        }
      }
    }
  }
}
